var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.add.outboundInvoice'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.add.outboundInvoice'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.invoiceNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.invoiceNumber').toLowerCase(),"rules":"required","vid":"invoiceNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.invoiceNumber').toLowerCase()},model:{value:(_vm.formData.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.formData, "invoiceNumber", $$v)},expression:"formData.invoiceNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.country').toLowerCase(),"rules":"","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.countryOptions},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.customerTaxNumber')}},[_c('validation-provider',{ref:"taxNumberIsValid",attrs:{"name":_vm.$t('form.customerTaxNumber').toLowerCase(),"rules":{required: _vm.required, taxNumber:_vm.formData.country.value.toUpperCase()},"vid":"customerTaxNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.formData.country.value.toUpperCase())+" ")])],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.customerTaxNumber').toLowerCase(),"formatter":_vm.taxNumberFormatter},on:{"input":_vm.queryTaxNumber},model:{value:(_vm.formData.customerTaxNumber),callback:function ($$v) {_vm.$set(_vm.formData, "customerTaxNumber", $$v)},expression:"formData.customerTaxNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.customerName')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.customerName').toLowerCase(),"rules":"required","vid":"customerName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.customerName').toLowerCase()},model:{value:(_vm.formData.customerName),callback:function ($$v) {_vm.$set(_vm.formData, "customerName", $$v)},expression:"formData.customerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.paymentMethod')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.paymentMethod').toLowerCase(),"rules":"required","vid":"paymentMethod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.paymentMethodOptions},model:{value:(_vm.formData.paymentMethod),callback:function ($$v) {_vm.$set(_vm.formData, "paymentMethod", $$v)},expression:"formData.paymentMethod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.appearance')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.appearance').toLowerCase(),"rules":"required","vid":"appearance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.appearanceOptions},model:{value:(_vm.formData.appearance),callback:function ($$v) {_vm.$set(_vm.formData, "appearance", $$v)},expression:"formData.appearance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.operation')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.operation').toLowerCase(),"rules":"required","vid":"operation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.operationOptions},model:{value:(_vm.formData.operation),callback:function ($$v) {_vm.$set(_vm.formData, "operation", $$v)},expression:"formData.operation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.category')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.category').toLowerCase(),"rules":"required","vid":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.categoryOptions},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.issueDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.issueDate').toLowerCase(),"rules":"required","vid":"issueDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.issueDate').toLowerCase()},model:{value:(_vm.formData.issueDate),callback:function ($$v) {_vm.$set(_vm.formData, "issueDate", $$v)},expression:"formData.issueDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.deliveryDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.deliveryDate').toLowerCase(),"rules":"required","vid":"deliveryDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.deliveryDate').toLowerCase()},model:{value:(_vm.formData.deliveryDate),callback:function ($$v) {_vm.$set(_vm.formData, "deliveryDate", $$v)},expression:"formData.deliveryDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.paymentDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.paymentDate').toLowerCase(),"rules":"required","vid":"paymentDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.paymentDate').toLowerCase()},model:{value:(_vm.formData.paymentDate),callback:function ($$v) {_vm.$set(_vm.formData, "paymentDate", $$v)},expression:"formData.paymentDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.currency')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.currency').toLowerCase(),"rules":"required","vid":"currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.currencyOptions},model:{value:(_vm.formData.currency),callback:function ($$v) {_vm.$set(_vm.formData, "currency", $$v)},expression:"formData.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.netAmount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.netAmount').toLowerCase(),"rules":"required","vid":"netAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.netAmount').toLowerCase()},model:{value:(_vm.formData.netAmount),callback:function ($$v) {_vm.$set(_vm.formData, "netAmount", $$v)},expression:"formData.netAmount"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.formData.currency.sign)+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.vatAmount')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.vatAmount').toLowerCase(),"rules":"required","vid":"vatAmount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.vatAmount').toLowerCase()},model:{value:(_vm.formData.vatAmount),callback:function ($$v) {_vm.$set(_vm.formData, "vatAmount", $$v)},expression:"formData.vatAmount"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.formData.currency.sign)+" ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.netAmountHUF')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.netAmountHUF').toLowerCase(),"rules":"required","vid":"netAmountHUF"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"1","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.netAmountHUF').toLowerCase()},model:{value:(_vm.formData.netAmountHUF),callback:function ($$v) {_vm.$set(_vm.formData, "netAmountHUF", $$v)},expression:"formData.netAmountHUF"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(" Ft ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.vatAmountHUF')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.vatAmountHUF').toLowerCase(),"rules":"required","vid":"vatAmountHUF"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","step":"1","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.vatAmountHUF').toLowerCase()},model:{value:(_vm.formData.vatAmountHUF),callback:function ($$v) {_vm.$set(_vm.formData, "vatAmountHUF", $$v)},expression:"formData.vatAmountHUF"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v(" Ft ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }